export const OBJECT = {
  ALLOCATION: 'ALLOCATION',
  CART: 'CART',
  CLUB: 'CLUB',
  CLUB_PACKAGE: 'CLUB_PACKAGE',
  CLUB_MEMBERSHIP: 'CLUB_MEMBERSHIP',
  CLUB_SIGNUP: 'CLUB_SIGNUP',
  COLLECTION: 'COLLECTION',
  COUPON: 'COUPON',
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_ADDRESS: 'CUSTOMER_ADDRESS',
  CUSTOMER_CREDIT_CARD: 'CUSTOMER_CREDIT_CARD',
  GIFT_CARD: 'GIFT_CARD',
  INVENTORY_LOCATION: 'INVENTORY_LOCATION',
  INVENTORY_TRANSACTION: 'INVENTORY_TRANSACTION',
  NOTE: 'NOTE',
  ORDER: 'ORDER',
  ORDER_FULFILLMENT: 'ORDER_FULFILLMENT',
  PRODUCT: 'PRODUCT',
  PROMOTION: 'PROMOTION',
  PROMOTION_SET: 'PROMOTION_SET',
  QUERY: 'QUERY',
  RESERVATION: 'RESERVATION',
  TAG: 'TAG',
  TRANSACTION_EMAIL: 'TRANSACTION_EMAIL'
};

export const OBJECT_TRANSLATED = {
  ALLOCATION: 'Allocation',
  CART: 'Cart',
  CLUB: 'Club',
  CLUB_PACKAGE: 'Club Package',
  CLUB_MEMBERSHIP: 'Club Membership',
  CLUB_SIGNUP: 'Club Signup',
  COLLECTION: 'Collection',
  COUPON: 'Coupon',
  CUSTOMER: 'Customer',
  CUSTOMER_ADDRESS: 'Customer Address',
  CUSTOMER_CREDIT_CARD: 'Customer Credit Card',
  GIFT_CARD: 'Gift Card',
  INVENTORY_LOCATION: 'Inventory Location',
  INVENTORY_TRANSACTION: 'Inventory Transaction',
  NOTE: 'Note',
  ORDER: 'Order',
  ORDER_FULFILLMENT: 'Order Fulfillment',
  PRODUCT: 'Product',
  PROMOTION: 'Promotion',
  PROMOTION_SET: 'Promotion Set',
  QUERY: 'Query',
  RESERVATION: 'Reservation',
  TAG: 'Tag',
  TRANSACTION_EMAIL: 'Transaction Email'
};

export const ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  BULK_UPDATE: 'BULK_UPDATE',
  DELETE: 'DELETE',
  SEND: 'SEND',
  EXECUTE: 'EXECUTE'
};

export const ACTION_TRANSLATED = {
  CREATE: 'Create',
  UPDATE: 'Update',
  BULK_UPDATE: 'Bulk Update',
  DELETE: 'Delete',
  SEND: 'Send',
  EXECUTE: 'Execute'
};
